<script setup lang="ts">
const router = useRouter()

const reloadPage = () => {
  window.location.reload()
}
</script>

<template>
  <div class="d-flex align-center ">
    <IconBtn
      class="me-1"
      @click="router.back()"
    >
      <VIcon
        size="26"
        icon="tabler-arrow-left"
      />
    </IconBtn>
    <IconBtn
      class="me-1"
      @click="router.forward()"
    >
      <VIcon
        size="26"
        icon="tabler-arrow-right"
      />
    </IconBtn>
    <IconBtn
      class="me-1"
      @click="reloadPage"
    >
      <VIcon
        size="26"
        icon="tabler-reload"
      />
    </IconBtn>
  </div>
</template>
